'use client'
import { type FC } from 'react'
import { useRouter, useSearchParams } from 'next/navigation'
import { useForm, type SubmitHandler } from 'react-hook-form'
import styled from 'styled-components'
import { useTranslator, useToast } from '@/hooks'
import { autoComplete, inputPattern } from '@/utils'
import { Button, Input } from '..'

type Props = {
}

type TypeFormInputs = {
  password: string
  password_confirmation: string
}

export const FormAuthResetPassword: FC<Props> = ({ }) => {
  const { register, handleSubmit, formState: { errors: formErrors }, setError } = useForm<TypeFormInputs>()
  const searchParams = useSearchParams()
  const { push } = useRouter()
  const { t } = useTranslator()
  const { addToast } = useToast()

  const onSubmit: SubmitHandler<TypeFormInputs> = async (formData) => {
    const token = searchParams.get('token')
    const body = {
      password: formData.password,
      password_confirmation: formData.password_confirmation,
      token
    }

    try {
      const res = await fetch('/api/auth/reset', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      })

      if (!res.ok && res.status === 422) {
        const data = await res.json()
        const { errors } = data

        if (errors) {
          for (let err of errors) {
            setError(err.field, { type: 'custom', message: err.message })
          }
        }
      } else if (!res.ok) throw new Error('FormAuthReset submit failed')
      else if (res.status === 200 || res.status === 204) {
        addToast({
          message: 'Contraseña se ha cambiado correctamente',
          type: 'success'
        })

        addToast({
          message: 'Redirigiendo a la página de inicio de sesión',
          type: 'loading'
        })

        push('/login')
      }
    } catch (e) {
      console.warn('Reset submit - Catch e', e)
      addToast({
        message: 'No se ha podido completar el reinicio de contraseña, contacte con soporte',
        type: 'error'
      })
    }
  }

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input type='password' name='password' register={register} label={t('form.fields.new_password') as string} placeholder='password' autoComplete={autoComplete.password.new} icon={'password'} bgColor={'background_login'} required={{
          required: t('form.errors.pattern_password') as string,
          pattern: inputPattern.password
        }} />
        <Input type='password' name='password_confirmation' register={register} label={t('form.fields.confirm_new_password') as string} placeholder='password' autoComplete={autoComplete.password.new} icon={'password'} bgColor={'background_login'} required={{
          required: t('form.errors.pattern_password') as string,
          pattern: inputPattern.password,
        }} error={formErrors.password_confirmation} />
        <Button type='submit'>Restablecer contraseña</Button>
      </Form>
    </>
  )
}

const Form = styled.form`
  label {
    padding-left: 0;
  }

  button {
    &[type='submit'] {
      margin: 40px 0 20px;
    }
  }
`
